.AuthorizationItem {
    margin: 30px 0;
}

.AuthorizationItem span {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #3B3663;
}

.authorization-item-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.authorization-item-options label {
    margin-right: 8px; 
}

.authorization-item-options input[type='radio'] {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.authorization-item-options input[type='radio'] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.authorization-item-options input[type='radio']:before {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: white;
    content: '';
    display: flex;
    visibility: visible;
    border: 1px solid black;
}

.authorization-item-options input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border: 1px solid black;
    border-radius: 12px;
    position: relative;
    top: -18px;
    left: 4px;
    
    background-color: #ffa500;
    content: '';
    display: flex;
}

