.serviceSummary {
    margin-top: 30px
}
.service-summary-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

.service-summary-content {
    background-color:  #E3E3E3;
    margin: 20px auto;
    padding: 30px 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
}

.service-line,
.item-line,
.service-line-total,
.sub-item-line {
    display: flex;
    justify-content: space-between;
}

.sub-item-line {
    padding-left: 15px;
}

.service-line-total{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
}

@media (max-width: 500px) {
    .service-line-total{
        font-size: 18px;
    }
}

@media print {
    .service-summary-title {
        font-size: 14px
    }
    .service-summary-content{
        margin: 0 auto;
        padding: 10px 20px;
        font-size: 12px
    }
    .service-line-total {
        font-size: 12px;
    }
}