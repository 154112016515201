.radio-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: calc(100% - 40px);
    padding: 30px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
}

.radio-name {
    margin: 0;
    padding: 0;
    display: flex;
}

.radio-group {
    display: flex;
    gap: 25px;
}
.radio-group div {
    display: flex;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
    background: #CCC;
    border-radius: 10px;
}

.radio-group-label{
    cursor: pointer;
    position: relative;
}

.radio-group-label-front {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;

}

.radio-group-label label {
    cursor: pointer;
}

.radio-generic-help {
    width: 35px;
    height: 35px;
    background-image: url("../../../assests/images/alert-circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    cursor: pointer;
}

@media (max-width: 1440px) {
    .radio-container {
        width: calc(100% - 40px);
        margin: 0 auto;
    }
}
@media (max-width: 550px) {
    .radio-name {
        text-align: center;
    }
    .radio-container{
        font-size: 16px;
    }

}