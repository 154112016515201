html, body, #app, #app>div {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  background: #f9f9f9;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --font-main: 'Roboto';
  --orange: #FF7A00;
  --active-blue: #00ABBA;
  --purple: #6254E7;
  --background: #f9f9f9;
  overflow-x: hidden;
}

/* body {
  font-family: var(--font-main);
} */

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Mulish&family=Roboto&display=swap');
