.footer {
    width: 100%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700 normal;
    text-align: center;
    font-size: 1vw;
    color: #000000;
    /* position: absolute;
    bottom: 0px; */

}

.child {
    padding-bottom: 40px;
    margin-bottom: 0px;
}

@media (min-width: 1025px) and (max-width: 1440px){
    .footer {
        font-size: 1.5vw;
    }
}

@media (min-width: 769px) and (max-width: 1024px){
    .footer {
        font-size: 1.6vw;
    }
}

@media (min-width: 426px) and (max-width: 768px){
    .footer {
        font-size: 2.5vw;
        text-align: center;
    }
}

@media (max-width: 525px) {
    .footer {
        font-size: 4vw;
        text-align: center;
        margin: 1rem auto;
    }
    .child {
        padding-bottom: 100px;
    }
    .child b {
        display: block;
    }
}

/* @media (max-width: 375px){
    .footer {
        font-size: 5vw;
        text-align: center;
    }
} */