.container {
    position: absolute;
    top: 0;
    display: flex;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: rgba(0, 0, 0, 0.75);
}

.details {
    position: fixed;
    margin: 0;
    padding: 0;
    background: #6254E7;
    width: 400px;
    height: 100vh;
    color: #F2F2F5;
    transition: .5s;
    z-index: 99;
}

.modal, .modal2 {
    background: #6254E7;
    position: fixed;
    width: 100px;
    height: 80px;
    top: 440px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px;
    color: #F2F2F5;
    z-index: 99;
}

.modal2 {
    left: 400px;
}

.modal h4, .modal2 h4 {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    
}

.modal span, .modal2 span {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
}

.btn {
    position: relative;
    background: #F2F2F5;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    top: -50px;
    left: 105px;
    background-image: url("../../assests/images/Vector.svg");
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.total {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    justify-content: space-between;
    margin: 20px 15px;

}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    margin-left: 15px;
}

@media (max-width: 950px) {
    .modal span, .modal2 span{
        display: none;
    }

    .modal h4, .modal2 h4 {
        transform: rotate(-90deg);
        margin: 30px 15px 0 0;
    }
    .modal, .modal2 {
        width: 60px;
        height: 90px;
        padding: 0;
        
    }

    .btn {
        left: 50px;
        top: -30px;
    }
    .modal2 {
        left: 250px;
    }

    .details {
        width: 250px;
        height: 100vh;

    }
}

@media (max-width: 500px) {
    .container {
        position: fixed;
        top: auto;
        bottom: 40px;
    }
    .modal {
        width: fit-content;
        padding: 0 20px 20px 20px;
        position: relative;   
        top: 0;     
    }
    .modal2 {
        width: fit-content;
        padding: 0 20px 20px 20px;
        position: relative;
        top: 0;
    }
    .modal h4, .modal2 h4 {
        transform: rotate(0deg);
        margin: 30px 15px 0 0;
    }
    .modal span, .modal2 span{
        display: block;
    }
    .btn {
        left: 80px;
        top: -53px;
    }
    .details {
        position: fixed;
        top: 0;
    }
}

