.container-buttons {
    width: 90%;
    display: flex;
    justify-content: center;
}

.container-buttons button {
        border-radius: 50px;
        width: 200px;
        height: 40px;
        margin: 4rem 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;
        border: none;
        cursor: pointer;
}

.btn-back {
    background: transparent;
    color: black;
}

.btn-next {
    background: var(--orange);
    color: #fff;
}

.btn-disabled:disabled {
    background: #C7C9D9;
    color: #fff;
}

@media (min-width: 769px) and (max-width: 1024px){
    .container-buttons button {
        border-radius: 50px;
        width: 150px;
        height: 35px;
        margin: 2rem 1rem;
        font-size: 14px;
    }
}

@media (min-width: 426px) and (max-width: 768px){
    .container-buttons button {
        border-radius: 50px;
        width: 120px;
        height: 30px;
        margin: 2rem 1rem;
        font-size: 10px;
    }
}

@media (max-width: 768px) {
    .container-buttons{
        flex-direction: column;
        width: 80%;
        margin: 0px auto;
    }
    .container-buttons button {
        border-radius: 50px;
        width: 100%;
        height: 40px;
        margin: 10px 0;
        font-size: 15px;
    }
}
/* 
@media (max-width: 375px){
    .container-buttons button {
        border-radius: 50px;
        width: 100px;
        height: 25px;
        margin: 2rem 1rem;
        font-size: 8px;
    }
} */
