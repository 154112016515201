
.social {
    margin-bottom: 2rem;
}
.line {
    /* width: 80%; */
    margin: 0 auto;
    border-top: 2px solid;
    margin-top: 30px;
}

.amount-post {
    /* width: 80%; */
    margin: 20px auto;
    color: var(--purple);
    display: flex;
    gap: 2rem;
}

.amount-post span {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    white-space: nowrap;
}

.posts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* width: 80%; */
    margin: 0 auto;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 20px;
    margin-top: 30px;
}

.posts span {
    font-weight: bold;
    margin-left: 20px;
}

p.section {
    /* width: 80%; */
    margin: 15px auto;
    font-family: 'Mulish';
    font-style: normal;
    font-size: 22px;
    margin-top: 2rem;
    color: var(--purple);
}

.addService{
    /* width: 80%; */
    margin: 0 auto;
    font-family: 'Mulish';
    font-style: normal;
    font-size: 1.5rem;
    margin-top: 1rem;
}
.radio-generic-help {
    width: 35px;
    height: 35px;
    background-image: url("../../assests/images/alert-circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    cursor: pointer;
}
.amount-post-group-help {
    display: flex;
    align-items: center;
}
.amount-post-group-help p {
    margin: 0;
}

.aditional-text textarea {
    width: 100%;
    height: 148px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
    background: #EDEDED;
}
.totalAmount {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    gap: 10px;
    margin: 10px 20px 0 0;
}
.totalAmount span {
    font-weight: bold;
}
@media (max-width: 1080px) {
    .amount-post {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .amount-post span {
        white-space: normal;
    }
}


@media (max-width: 550px) {
    p.section, .addService, .aditional-text textarea {
        font-size: 18px;
    }
    .amount-post span {
        font-size: 18px;
    }
    
}

