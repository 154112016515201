.landingPage {
    width: 53%;
    margin: 0 auto;
}

.landing-page-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3A3A3C;
    margin: 20px 0;
    white-space: nowrap;
}

.landing-page-sub-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;    
    color: #000000;
}

.landing-page-title span {
    color: var(--orange);
    padding-left: 10px;
}

.landing-page-options {
    display: flex;
    justify-content: space-between;
}

.landing-page-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    color: #3B3663;
}

.landing-page-message-header {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    color: #000000;
    display: flex;
    flex-direction: column;
    margin: 50px 0;
}

.landing-page-services {
    width: 80%;
    margin: 40px auto;
}

.landing-page-content-title,
.landing-page-content-1,
.landing-page-content {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    color: #000000;
    text-align: justify; 
}
.landing-page-content-1 {
    font-size: 20px;
    color: #696687;
}

.landing-page-content-title {
    font-size: 20px; 
    font-weight: 700; 
}
.landing-page-total-quote,
.landing-page-deduction,
.item-line-total-quote {
    display: flex;
    justify-content: space-between;  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    font-size: 20px; 
    
}

.landing-page-total-quote{
    margin-top: 20px;
}

.item-line-total-quote {
    font-size: 24px;
    margin-top: 20px; 
    border-top: 1px solid #3B3663;
    padding-top: 15px;
}

@media (max-width: 768px) {
    .landingPage {
        width: 90%;
    }
    .landing-page-title {
        font-size: 30px;
    }
    .landing-page-sub-title {
        font-size: 24px;
    }
    .landing-page-name {
        font-size: 26px;
    }
    .landing-page-services {
        width: 90%;
    }
}

@media (max-width: 500px) {
    .landing-page-title {
        display: inline-grid;
    }
    .landing-page-title span {
        padding: 0;
    }
}

@media (max-width: 500px) {
    .landing-page-title {
        font-size: 22px;
    }
    .landing-page-sub-title {
        font-size: 20px;
    }
    .landing-page-name {
        font-size: 20px;
    }
    .landing-page-content-1,
    .landing-page-content {
        font-size: 18px;
    }
    .landing-page-total-quote,
    .landing-page-deduction,
    .item-line-total-quote {
        font-size: 18px; 
    }
}

@media print {
    .landingPage{
        width: 95%;
    }
    .landing-page-print{
        display: none;
    }

    .landing-page-title{
        font-size: 24px;
    }
    .landing-page-name{
        font-size: 18px;
    }
    .landing-page-message-header{
        margin: 35px 0;
    }
    .landing-page-content-1,
    .landing-page-content,
    .landing-page-message-header {
        font-size: 12px;
    }

    .landing-page-content-title {
        font-size: 14px;
    }
    .landing-page-sub-title {
        font-size: 16px;
    }
    .landing-page-services {
        margin: 20px auto;
    }
    .landing-page-total-quote,
    .landing-page-deduction {
        font-size: 12px;
    }
    .item-line-total-quote {
        font-size: 16px;
    }
    
}
