.card-container {
    display: flex;
    flex-direction: column;
    width: 477px;
    cursor: pointer;
}

.card-img {
    width: 100%;
    height: 229px;
}

.card-img img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
}

.card-information {
    padding: 20px 30px;
}

.card-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    /* or 42px */

    display: flex;
    align-items: center;
    justify-content: center;
}

.card-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
    /* text-align: justify; */
}

.card-text div {
    display: inline;
    width: fit-content;
    font-weight: 500;
}

.card-active {
    color: white
}

.card-deactive {
    color: var(--orange);
}

.active {
    border-radius: 32px;
    background: var(--active-blue);
}

@media (max-width: 1440px) {
    .card-container{
        width: 100%;
    }
}