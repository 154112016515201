.client-page {
    width: 65%;
    margin: 0 auto;
}

.client-grid{
    width: 85%;
    margin: 3rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.client-description{
    margin: 15px;
    margin-top: 30px;
}

.client-grid-right{
    border-left: 1px solid #6B7588;
    margin-left: 30px;
    padding-left: 40px; 
}
.client-information-button {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
}

.client-information-button {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.client-information-button button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border: none;
    width: 230px;
    height: 56px;
    border-radius: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
}

.client-information-button-next{
    background-color: var(--orange);
    color: #F2F2F5;
    cursor: pointer;
}

.client-information-button-back{
    background-color: transparent;
    color: black;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .client-page {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .client-grid{
        width: 85%;
        grid-template-columns: repeat(1, 1fr);
    }
    .client-grid-right{
        border-left: none;
        margin-left: 0;
        padding-left: 0;
    }
    .client-description{
        margin: 0;
    }
}

@media (max-width: 570px) {
    .client-grid {
        display: flex;
        flex-direction: column;
    }
}

