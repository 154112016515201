.background {
    position: relative;
    width: 100%;
    height: 85vh;
}

.background img {
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.content {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    margin: 0px auto;
    margin-bottom: 10px;
    /* padding: 0 2rem; */
    /* margin: 2rem auto; */
    /* background-color: red; */
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%) 
    
}

.header {
    width: 100%;
    font-size: 25px;
    color: #6254E7;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 105%;
    margin: 2rem 0 0 0;
}

.services {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3,1fr);
    column-gap: 1rem;
    margin: 2rem auto;
    text-align: center;
}
@media (max-width: 768px) {
    .services {
        grid-template-columns: repeat(1,1fr);
    }
    .content {
        margin: 20px auto;
        height: auto;
    }
    
}
@media (max-width: 500px) {
    .content {
        display: flex;
        width: 90%;
    }
    .header {
        font-size: 22px;
    }
    
}
/*
@media(max-width: 1440px){
    .content {
        max-width: 1100px;
        margin: 2rem auto;
    }
}

@media(max-width: 1220px){
    .content {
        max-width: 800px;
        margin: 2rem auto;
    }
}

@media (max-width: 900px){
    .content{
        margin: 0 auto;

    }
    .services {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        margin: 1rem auto;
        text-align: center;
    }
} */

/* @media (min-width: 1025px) and (max-width: 1440px){
    h3 {
        font-size: 20px;
    }
}

@media (min-width: 769px) and (max-width: 1024px){
    h3 {
        width: 600px;
        font-size: 18px;
    }
    .services {
        width: 600px;
    }
}

@media (min-width: 426px) and (max-width: 768px){
    h3 {
        width: 400px;
        font-size: 16px;
    }
    .services {
        width: 400px;
        column-gap: 2px;
    }
}

@media (max-width: 426px) {
    .services {
        grid-template-columns: repeat(2,1fr);
        column-gap: 1rem;
        width: calc(100% - 60px);
        margin-top: 1rem;
        text-align: center;
    }
    h3 {
        width: 100% ;
        font-size: 16px;
    }
} */

/* @media (max-width: 375px){
    .services {
        grid-template-columns: repeat(3,1fr);
        column-gap: 5px;
        width: 280px;
        margin-top: 1rem;
        text-align: center;
    }
    h3 {
        width: 280px;
        font-size: 16px;
    }
} */
