.deductionCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 17px;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0px 7px 18px rgb(0 0 0 / 25%);
    margin-bottom: 15px;
    cursor: pointer;
}
.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #3B3663;
    margin: 20px 0;
}
.deduction-card-text {
    display: flex;
    flex-direction: column;
}

.deductionCard input[type='radio'] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.deductionCard input[type='radio']:before {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 2px;
    left: 0px;
    position: relative;
    background-color: white;
    content: '';
    display: block;
    visibility: visible;
    border: 1px solid black;
}

.deductionCard input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border: 1px solid black;
    border-radius: 12px;
    top: -16px;
    left: 4px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: block;
    /* margin: 0; */
    /* visibility: visible; */
}



.deduction-card-text span:first-child {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #3B3663;
}

.deduction-card-text span:last-child {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #696687;
}

.deduction-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 17px;
    background: #FFFFFF;
    box-shadow: 0px 7px 18px rgb(0 0 0 / 25%);
    border-radius: 6px;
}

.deduction-card span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #3B3663;
}

.deduction-card input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    padding: 10px;
    background: #FFFFFF;
    /* box-shadow: 0px 7px 18px rgb(0, 0, 0, 0.25); */
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    width: 260px;
    border: 1px solid #a8a7b5
}

.deduction-card input:focus {
    outline: none;
}

.deduction-card button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    width: 100px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid var(--orange);
    border-radius: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #3A3A3C;
}

@media (max-width: 545px){
    .deduction-card{
        display: flex;
        flex-direction: column;
    }
    .deduction-card button{
        width: 100%;
    }
}
