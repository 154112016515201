.container {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color: #3B3663;
}
.welcome{
    margin-top: 50px;
    min-width: 530px;

}

.img > img {
    width: 430px;
    height: 500px;
    object-fit: hidden;    
}


.welcome h1, .welcome-dinamic {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 27px;
    line-height: 105%;
    color: #6B7588;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    margin: 10px 50px 10px 0;
}
.welcome-dinamic {
    color: #6254E7;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 2rem;
}
/* h1#wmd {
    color: #6254E7;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 2rem;
} */

.text {
    margin: 0 4.7vw auto;
}

.selects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.button {
    display: flex;
    justify-content: center;
}

.containerPhone{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

}

.containerPhone div {
    background-color: #fff;
}

.fieldContainer {
    border: 1px solid #b8b6b6;
    border-radius: 5px;
    padding: 5px;
}

.containerPhone input{
    outline: none;
    border: none; 
    padding: 8px 16px;
}

.containerPhone label{
    margin-bottom: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #3B3663;
}

@media (min-width: 1440px) {
    .content {
        max-width: 1100px;
    }
    
}

@media (max-width: 1200px){
    .content {
        gap: 2rem;
    }
    .img > img {
        margin-top: 40px;
        width: 360px;
        height: 420px;
        object-fit: hidden;    
    }
}

@media (max-width: 1024px){
    .content {
        max-width: fit-content;
        margin: 0 auto;
    }
    .img > img {
        margin-top: 40px;
        width: 280px;
        height: 320px;
        object-fit: hidden;    
    }
    .welcome h1 {
        font-size: 18px;
    }
    
    h1#wmd, .welcome-dinamic {
        font-size: 34px;
    }    
}

@media (max-width: 768px){
    .img {
        display: none;
    }
    .welcome {
        min-width: 380px;
    }
}

@media (max-width: 475px){
    .img {
        display: none;
    }
    .welcome h1 {
        font-size: 14px;
    }
    
    h1#wmd, .welcome-dinamic {
        font-size: 24px;
    } 
    .welcome {
        min-width: 300px;
    }
}
