.service {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 10px 15px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
}

.serviceContainer {
    display: flex;
    cursor: pointer;
}

.serviceBtn {
    width: 20px;
    height: 20px;
    background-image: url("../../assests/images/chevron-down.png");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 10px;
    position: relative;
    top: 5px;
}

.item {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 5px 30px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 170%;
}



.item2 {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 5px 40px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 170%;
}

.item2 span:first-child {
    margin-right: 20px
}
