.modal-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    color: #6254E7;
}

.modal-icon {
    width: 40px;
    height: 40px;
    background-image: url("../../assests/images/alert-circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    padding: 0;
}

.modal-close {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
}