.admin-web-container {
    padding: 20px;
}

.admin-web-items {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.admin-web-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
} 

.admin-web-title {
    font-size: 28px;
}

.admin-web-body {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}

.admin-web-body-span {
    font-weight: bold;
}

.admin-web-check-item {
    min-width: 35px;
    min-height: 35px;
    background-image: url("../../../assests/images/check.svg");
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 1024px) {
    .admin-web-items {
        gap: 5px;
    }
    .admin-web-title {
        font-size: 22px;
    }

    .admin-web-header {
        font-size: 16px;
    } 
    
    .admin-web-body {
        font-size: 14px;
    }
}