.text-input {
    width: 100%;
}
.text-input label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #3B3663;
}

.text-input input {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #3A3A3C;
    border-radius: 5px;
    border: 1px solid #b8b6b6;
    height: 30px;
    width: 100%;
    margin-bottom: 15px;
    padding: 8px;
}

.invalid {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: red;
    padding-bottom: 15px;
}