.container{
    /* width: 80%; */
    margin: 0 auto;
    margin-top: 30px;
    border: 1px solid #b8b6b6;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3vw;
    padding: 1.5rem 0;
}
.title {
    display: flex;
    align-items: center;
    min-width: 400px;
    width: 150%;
}

.help {
    width: 35px;
    height: 35px;
    background-image: url("../../assests/images/alert-circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    cursor: pointer;
}

.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    margin-left: 30px ;
    /* white-space: nowrap */
}

.fraction {
    position: relative;
    text-align: right;
    font-size: 1.4rem;
    margin-right: 30px;
    max-width: 100px;
    min-width: 70px;
}
@media (max-width: 1024px) {
    .title {
        min-width: auto;
    }
    /* .text {
        font-size: 16px;
    } */
}
    
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        /* width: 75%; */
        /* margin: 30px 15px 0 15px; */
        padding: 15px;
        gap: 0;
    } 
    .title {
        width: 100%;
    } 
    .text { 
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        margin: 0;
    } 
    .fraction {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 170%;
    }
    .text { 
        font-size: 18px;
    } 
}

@media (max-width: 550px) {
    .text { 
        font-size: 16px;
    } 
}