.text-input {
    margin-top: 15px;
}

.text-input label {
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
}

.text-input input {
    width: calc(100% - 22px);
    height: 30px;
    padding: 5px 10px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    border-radius: 5px;
    color: #3A3A3C;
    border: 1px solid #b8b6b6;
}

.text-input input:focus {
    outline: none;
}

@media (max-width: 500px) {
    .text-input label {
        font-size: 3.7vw;
    }
    .text-input input {  
        font-size: 3.7vw;
    }
}

/* @media (max-width: 375px){
    .text-input label {
        font-size: 3.5vw;
    }
    .text-input input {  
        
        width: calc(100% - 3px);
        padding: 3px 0px 3px 3px;
        font-size: 3.5vw;
    }
} */