.button button {
    color: #fff;
    border-radius: 10px;
    width: 220px;
    height: 45px;
    margin-top: 3rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    border: none;
    cursor: pointer;
}

.button-active {
    background: #FF6200;
    
}

.button-disabled {
    background: #F2F2F5;
}