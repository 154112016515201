.container {
    width: 100%;
    background: white;
    border: 2px solid  #DDE5E9;
    z-index: 1;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    width: 140px;
    height: 50px;
    object-fit: cover;
}

.line {
    margin: 0 10px;
    width: 30px;  
    height: 1px;
    background: #3A3A3C;
    transform: rotate(90deg);
}
.logo p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #3A3A3C;
    /* width: 165px; */
    height: 30px; 
}
.nav { 
    list-style: none;
}

.back {
    position: static;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: var(--orange);
    text-decoration: none;
    position: relative;
}


.btnToggle{
    border: none;  
    background: none;
    position: absolute;
    right: 15px;
    display: none;
}
.toggle{
    width: 20px;
    height: 20px;
    background-image: url("../../assests/images/Menu.svg");
    background-repeat: no-repeat;
    background-position: center;
}


.landing-page-print button{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #3A3A3C;
    border: none;
    background-color: transparent;
}
.landing-page-print button img{
    margin-right: 10px;
}

@media (min-width: 1440px) {
    .navigation {
        max-width: 1200px;
        margin: 0 auto;
    }
}
@media (max-width: 640px) {
    .nav {
        display: none;
    }
    /* .btnToggle {
        display: block;
    } */
    .landing-page-print-text {
        display: none
    }
    .landing-page-print {
        position: absolute;
        right: 50px;
    }
    
}
@media (max-width: 530px){
    .navigation {
        width: 90%;
    }
    .logo p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #3A3A3C;
        width: 165px;
        height: 30px; 
    }
    .logo img {
        width: 90px;
        height: 35px;
    }
    
    .line {
        margin: 0;
        width: 28px;  
        height: 1px;
        background: #3A3A3C;
        transform: rotate(90deg);
    }
}
@page {
    size: A4;
    margin: 11mm 17mm 17mm 17mm;
}
@media print {
    .nav, 
    .line,
    .btnToggle {
        display: none;
    }
    .logo {
        margin: 0 auto;

    }
    .logo p {
        display: none;
    }
    .container {
        border: none;
    }

}