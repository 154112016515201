.admin-group-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .admin-group-container {
        flex-direction: column;
        margin-bottom: 30px;

    }
}