.item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    border: 1px solid #b8b6b6;
    border-radius: 10px;
    /* width: 80%; */
    margin: 0 auto;
    margin-top: 1rem;
}
.content {
    display: flex;
    justify-content: space-between;
}
.text {
    margin: 20px;
    padding: 0;
}

.item p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
    margin: 0;
}

.item p.title {
    font-weight: bold;
}

.options {
    display: flex;
    align-items: center;
    padding: 25px;
}

.options label {
    width: 16px;
    height: 18px;
    left: 0px;
    top: 3px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    color: #000000;
    margin: 0px 10px 0px 25px;
}
.options input {
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
}

.numeric {
    width: 200px;
    margin: 0px 0px 20px 15px;
}

@media (max-width: 718px) {
    .item {
        flex-direction: column;
    }
}

@media (max-width: 550px) {
    .item p {
        font-size: 16px;
    }
}