.elements {
    /* width: 85%; */
    margin: 0 260px;
}

.progress {
    margin-top: 2rem;
}

.custom-services-description{
    /* width: 90%; */
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .elements {
        margin: 0 90px;
    }
}

/* @media (max-width: 768px) {
    .elements {
        width: 95%;
    }
} */
@media (max-width: 768px) {
    .elements {
        margin: 0 30px;
    }
}




