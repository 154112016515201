@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;1,200&display=swap');


.wrapper__data-polices{
    padding: 20px;
    margin-top: 30px;
}
@media(min-width:1300px){
    .wrapper__data-polices{
    max-width: 1200px;
    margin: 0 auto;
    }
    .wrapper__data-polices h1{
        font-size: 40px;
    }
}

.wrapper__data-polices h1{
    color: #6B5A24;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}
@media(min-width:800px){
    .wrapper__data-polices h1{
        font-size: 30px;
    }
}
.wrapper__data-polices p{
    font-size: 21px;
    color: grey;
}
.wrapper__data-polices span{
   font-size: 20px;
    font-weight: bold;
    /* color: grey; */
}
.wrapper__data-polices span + p{
    margin-top: 0;
}
.wrapper__data-polices ol li,
.wrapper__data-polices ul li{
    list-style: block;
    font-size: 19px;
    color: grey;
}