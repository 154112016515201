.card-admin-wrapper{
    position: relative;
    box-sizing: border-box;
    width: 31%;
    margin-top: 40px;

}

.card-admin-overlay{
    width: calc(100% - 60px);
    height: calc(100% - -17px);
    border-radius: 0.5rem;
    background-color: var(--orange);
    position: absolute;
    transform-origin: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -49.5%) rotate(-5deg);
    z-index: -1;
}

.active {
    background-color: var(--active-blue);
}

.card-admin-content {
    border-radius: 0.5rem;
    height: 100%;
    background-color: #f9f9f9;
    border: 2px solid var(--orange);
    z-index: 0; 
    cursor: pointer;
}

.active-content {
    border: 2px solid var(--active-blue);
}

@media (max-width: 768px) {
    .card-admin-wrapper{
        width: 80%;
        margin: 20px auto;

    }
}