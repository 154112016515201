.container {
    width: 100%;
    margin: 0 auto;
}

.container label {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
.inputnumber {
    border: 1px solid #b8b6b6;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
}
  
.inputnumber button {
    width: 25px;
    height: 25px;
    margin: 5px 10px;
    background: #C4C4C4;
    color: black;
    font-size: 15px;
}

.inputnumber input[type=number]::-webkit-inner-spin-button, 
.inputnumber input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}

.inputnumber input {
    width: 30px;
    height: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-left: 10px;
    border: 0;
    cursor: default;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    outline: none;
    background-color: transparent;
}
.btnNumbers button {
    border: none;
    border-radius: 50%;
}
.btnNumbers button:hover {
    background-color: var(--orange);
    cursor: pointer;
}
.plus {
    position: relative;
    width: 10px;
    height: 10px;
    background-image: url("../../../assests/images/plus.svg");
    background-repeat: no-repeat;
    background-position: center;
    /* margin: 0 10px; */
    left: 1.5px;
    top: 0px;
}

.minus {
    position: relative;
    width: 10px;
    height: 10px;
    background-image: url("../../../assests/images/minus.svg");
    background-repeat: no-repeat;
    background-position: center;
    /* margin: 0 10px; */
    left: 1.5px;
    top: 0px;
}

@media (max-width: 550px) {
    .inputnumber {
        margin: 0 10px;
    }
}
