/* *, ::after, ::before { box-sizing: border-box; } */
.responsive {
  display: none;
}
.steplist {
  width: 100%;
  margin: 0 auto !important;
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap !important;
  border-top: 2px solid #C4C4C4;
  display: inline-flex;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  list-style-type: none;
  /* gap: 7em; */
  margin: 0;
  padding: 0;
  --circle-radius: 8px;
}

.steplist li {
  color: black;
  cursor: pointer;
  padding: calc(5px + var(--circle-radius)) 0 0;
  margin: 0;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

i {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid black;
  position: relative;
  background: #C4C4C4;
  top: -26px;
}

.steplist li::before {
  background-color: #f9f9f9;
  border: 1px solid black;
  border-radius: 50%;
  content: '';
  position: absolute;
  height: 24px;
  width: 24px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.steplist .active {
  color: black;
}

.steplist .active::before {
  background-color: #f9f9f9;
  width: 35px;
  height: 35px; 
}

.steplist li:first-child::after {
  background-color: #f9f9f9;
  content: '';
  position: absolute;
  height: 2px;
  width: calc(50% - 13px);
  top: -2px;
  /* Posicionar lineal al borde del circulo lado izquierdo */
  left: 0;
}

.steplist li.active:first-child::after {
  /*Posicionar lineal al borde del circulo lado izquierdo*/
  left: -5.5px;
}

.steplist li:last-child::after {
  background-color:#f9f9f9;
  content: '';
  position: absolute;
  height: 2px;
  width: calc(50% - 12px);
  top: -2px;
  /*Posicionar lineal al borde del circulo lado derecho*/
  left: calc(50% + 13px);
}

.steplist li.active:last-child::after {
  left: calc(50% + 18.5px);
}

.steplist .end::before {
  background: #C4C4C4;
}

.text span {
    font-weight: bold;
    margin: 0;
}

.text p {
  margin: 10px 0 0 0;
  text-align: center;
  max-width: 200px;
}


.service-card{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 16px;
  font-weight: normal;
  background-color: #e9e9e9;
  padding: 20px 0;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  margin-right: 50px ;
  position: relative;
}

.service-card:before {
  content: " ";
  width: 43px;
  height: 43px;
  background-color: var(--background);
  position: absolute;
  top: 0px;
  left: 0;
  transform-origin: top left;
  transform: rotate(45deg);
}

.service-card::after {
  content: " ";
  width: 41px;
  height: 42px;
  background-color: #e9e9e9;
  position: absolute;
  top: 0px;
  right: 0;
  transform-origin: top right;
  transform: rotate(-45deg);
}

.active-card, 
.active-card::after {
  background-color: var(--purple);
}

.inactive-card, 
.inactive-card::after {
  background-color: #C4C4C4;
}

@media (max-width: 525px) {
  .responsive {
    display: block;
  }
  .allScreen {
    display: none;
  }
}



