.service-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 8px; */
    /* width: 350px; */
    height: 250px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
    
}

.service-card-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 8px; */
    /* width: 350px; */
    height: 250px;
    width: 100%;
    background: var(--active-blue);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    text-transform: capitalize;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
    background: #CCC;
}

.logo {
    margin: 2vh;
}

/* .card-logo-text {
    margin-bottom: 2vh;
} */

 @media(max-width: 1024px){
    .service-card,
    .service-card-active {
        height: 200px;
    }
}
/* 
@media(max-width: 768px){
    .service-card,
    .service-card-active {
        height: 150px;
    }
    .logo {
        margin: 1vh;
    }
    
    .card-logo-text {
        margin-bottom: 1vh;
    }
} */

/*@media(max-width: 1220px){
    .service-card,
    .service-card-active {
        width: 100%;
        height: 150px;
        padding: 12px;
    }
} */

/*@media (min-width: 769px) and (max-width: 1024px){
    .service-card,
    .service-card-active {
        width: 100px;
        height: 100px;
        padding: 22px;
    }
}

@media (min-width: 426px) and (max-width: 768px){
    .service-card,
    .service-card-active {
        width: 50px;
        height: 50px;
        padding: 20px;
        font-size: 10px;
        border-radius: 12px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        margin: 5px 0px;   
    }
}

@media (max-width: 426px) {
    .service-card,
    .service-card-active {
        width: 90%;
        height: 150px;
        font-size: 10px;
        border-radius: 12px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
        margin: 10px 0px;   
    }
} */

/* @media (max-width: 375px){
    .service-card {
        width: 35px;
        height: 35px;
        padding: 25px;
        font-size: 10px;
        border-radius: 12px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
        margin: 5px 0px;   
    }
} */