.form-control {
    height: 45px !important; 
    width: 100% !important;
  }
.react-tel-input {
    font-family: 'Mulish' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    display: flex !important;
    align-items: center !important;
    color: #3A3A3C !important;
    border-radius: 5px !important;
    height: 30px !important;
    width: calc(100% + 18px) !important;
  }
  
  .flag-dropdown {
    top: -8px !important;
    height: 45px !important;
  }