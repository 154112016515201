.social-item-container {
    width: 80%;
    height: 60px;  
    padding: 0 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 100px 10px 10px 100px; 
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.social-item-elemment {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.social-item-elemment i {
    position: relative;
    top: 0;
}

.social-item-elemment img {
    width: 25px;
    height: 25px;
}

.social-item-elemment span {
    padding: 0 10px;
}

.social-item-select {
    width: 21px;
    height: 21px;
    border: 1px solid;
    border-radius: 2px;
    
}
.active {
    
    border: none;
    background-color: var(--orange);
}

@media (max-width: 550px) {
    .social-item-elemment{
        font-size: 16px;
    }
    
}
