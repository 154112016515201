.customMarketing {
    display: flex;
    flex-direction: column;
    border: 1px solid #b8b6b6;
    border-radius: 10px;
    /* width: 80%; */
    margin: 0 auto;
    margin-top: 1rem;
}

.custom-no-marketing {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
}



.custom-marketing-container {
    margin: 20px;
    padding: 0;
}


.customMarketing p,
.custom-marketing-message, 
.custom-no-marketing {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
    margin: 0;
}

.customMarketing p.title {
    font-weight: bold;
}

.MuiSlider-rail {
    color: #be1a2a;
}

.custom-marketing-message {
    width: 100%;
    text-align: center;
    color: var(--purple);
}
.custom-marketing-error {
    color: red;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
}

.custom-slider-container {
    display: flex;
    gap: 3em;
}

.custom-slider {
    width: 66.6%;
}

.custom-slider-value {
    color: var(--purple);
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: fit-content;
    margin: 0 auto; 
}

.custom-slider-value span {
    white-space: nowrap;
}

@media (max-width: 950px) {
    .custom-slider-container {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .custom-slider {
        width: 100%;
    }
}
@media (max-width: 550px) {
    .customMarketing p,
.custom-marketing-message, 
.custom-no-marketing,
.custom-slider-value {
    font-size: 16px;
}
}
@media (max-width: 400px) {
    .custom-slider-value{
        flex-direction: column; 
        gap: 0;
    }
}