.select-input {
    margin-top: 15px;
}

.select-input label {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
}

.select-input select {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    border-radius: 5px;
    color: #6B7588;
    border: 1px solid #b8b6b6;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-image: url("../../../assests/images/VectorSelect.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
}



@media (max-width: 500px) {

    .select-input label {
        font-size: 3.7vw;
    }
    .select-input select {  
        padding: 5px 5px;
        font-size: 3.7vw;
    }
}

/* @media (max-width: 375px){
    .select-input label {
        font-size: 3.5vw;  
    }
    .select-input select {  
        padding: 3px 5px;
        font-size: 3.5vw;
    }
} */