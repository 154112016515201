.social-group-container {
    display: grid; 
    /* width: 80%; */
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr); 
    gap: 1.5rem;
}

@media (max-width: 920px) {
    .social-group-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 560px) {
    .social-group-container {
        grid-template-columns: repeat(1, 1fr); 
    }
}