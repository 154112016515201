.quoteInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.containerButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.client-title{
    margin: 0 0 10px 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    display: flex;
    align-items: center;

    /* Secondary 1 */

    color: #3B3663;
}

.client-total{
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
}


.client-total h4 {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    display: flex;
    align-items: center;

    color: #424242;
}

.client-quote-back {
    background-color: var(--orange);
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    text-align: right;
    padding: 15px;
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    /* position: absolute;
    right: 0;
    margin-top: 20px; */
}

@media (max-width: 500px) {
    .client-title{
        font-size: 20px;
    }
    .client-total h4 {
        font-size: 18px;
    }
}