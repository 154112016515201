.client-form{
    width: 100%;
    margin-bottom: 50px;
}

.client-title{
    margin: 0 0 20px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #3B3663; 
}

/* .phoneInput{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.phoneInput label{
    margin-bottom: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #3B3663;
} */

@media (max-width: 500px) {
    .client-title{
        font-size: 20px;
    }
}