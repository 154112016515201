.pageTypeTitle {
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: var(--orange);
    font-weight: 600;
    font-family: 'Inter';
}

.webpage-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    width: fit-content;
    margin: 0 auto;
    margin-top: 50px;
}

.webpage-options-container {
    width: 100%;
    margin: 0 auto;
}

@media (max-width: 1440px) {
    .webpage-card-container {
        width: 90%;
        gap: 3rem;
    }
    .webpage-options-container {
        width: 90%;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .webpage-card-container {
        gap: 1rem;
    }
}

@media (max-width: 600px) {
    .webpage-card-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .pageTypeTitle {
        font-size: 22px;
    }
}