.summary-container {
    display: flex;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}
.summary-container-group{
    display: flex;
    animation: showDetails 0.5s;
}

.summary-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    animation: showDetails 0.5s;
    z-index: 1;
}

.summary-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    margin-left: 15px;
    color: white;
    padding: 20px 0px 0px;
}
.summary-details {
    width: 400px; 
    height: 100%;
    z-index: 1;
    background-color: var(--purple);
    overflow-y: auto;
}



.summary-redux-wrapper, .summary-redux-wrapper-group {
    height: 100%;
    width: fit-content;
}
.summary-redux {
    width: 150px;
    height: 120px; 
    padding: 20px;
    background-color: var(--purple);
    position: absolute;
    top: 300px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    animation: showDetails 0.5s;
    cursor: pointer;

}

.summary-redux-button{
    position: relative;
    top: 90px;
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--purple);
}
.summary-redux-button-show {
    top: 80px;
}
.summary-redux-button div{
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid var(--purple);
    background-image: url(../../../assests/images/chevron-up.svg);
    background-repeat: no-repeat;
    background-position:center;
}
.summary-redux-button span {
    text-align: center;
}

.summary-redux-total-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 25px;
}

.summary-redux-total-title,
.summary-redux-total-amount {
    font-family: 'Inter';
    font-style: normal;
    line-height: 150%;
    color: white;
}

.summary-redux-total-title {    
    font-weight: 700;
    font-size: 20px;
}

.summary-redux-total-amount {
    font-weight: 400;
    font-size: 18px;
}

/**/
.summary-item-container {
    display: flex;
    gap: 30px;
    padding: 5px 30px 5px 0;
    justify-content: space-between;
    align-items: center;
    color: white;
    cursor: pointer;
}

.summary-item-btn-down {
    width: 20px;
    height: 20px;
    -webkit-mask: url("../../../assests/images/chevron-down.png") no-repeat center;
    mask: url("../../../assests/images/chevron-down.png") no-repeat center;
    margin-right: 10px;
}

.summary-item-group {
    display: flex;
    align-items: center;
}

.summary-details-total{
    display: flex;
    padding: 20px 30px 0 15px;
    padding-top: 20px;
    justify-content: space-between;
}
.summary-detail-close {
    width: 30px;
    height: 30px;
    background-image: url("../../../assests/images/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 10px 10px 0 auto;
    display: none;
}

@media (max-width: 1024px) {
    /* .summary-redux {
        top: 80vh;
    } */
    .summary-redux-button{
        display: none;
    }
    /* .summary-redux {
        display: none;
    } */
    .summary-redux-hide {
        transform: rotate(-90deg);
        border-radius: 0px 0px 10px 10px;
        padding: 0 20px;
        top: calc(80vh - 30px);
        left: -35px;
    }
}

@media (max-width: 525px) {
    .summary-redux-wrapper, .summary-container {
        width: 100%;
        
        position: fixed;
        left: 0;
        top: calc(100vh - 80px);
    }
    .summary-redux {
        transform: rotate(0deg);
        padding: 0;
        border-radius: 0;
        width: 100%;
        top: 0;
        left: 0;
    }
    .summary-detail-close{
        display: block;
    }
    .summary-backdrop, .summary-redux-wrapper-group {
        display: none;
    }
    .summary-container-group, .summary-details {
        width: 100%;
    }

    .summary-redux-total-container {
        flex-direction: row;
        gap: 10px;
        left: calc((100% / 2) - 115px)
    }

}
@keyframes showDetails {
    0% {
       opacity: 0;
    }
   
    100% {
       opacity: 1;
    }
}