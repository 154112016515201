.combowrapper {
    /* max-width: fit-content; */
    height: fit-content;
    /* border-radius: 32px; */
    padding: 30px 0px;
    margin: 0 auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 1px solid black;
}

@media (max-width: 1440px) {
    .combowrapper {
        max-width: none;
        width: 90%;

    }
}