.cardwrapper {
    /* max-width: fit-content; */
    height: fit-content;
    /* border-radius: 32px; */
    /* padding: 30px 20px; */
    margin: 0 auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    /* box-shadow: 4px 4px 30px 1px rgba(0, 0, 0, 0.2); */
}

@media (max-width: 1440px) {
    .cardwrapper {
        max-width: none;
        /* width: 90%; */
    }
}