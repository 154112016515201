.radio-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1000px;
    padding: 30px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
}

.radio-name {
    margin: 0;
    padding: 0;
}

.radio-group {
    display: flex;
}

.radio-generic-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* width: 960px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    padding: 0px 20px;
}

.radio-generic-container label {
    white-space: nowrap;
}

.radio-generic-container-extra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin: 5px 0;
}

.radio-generic-container-extra input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b8b6b6;
    border-radius: 10px;
    outline: none;
}
.radio-generic-container-extra button {
    border: none;
    width: 25px; 
    height: 25px;
    border-radius: 50%;
    background: #C4C4C4; 
}
.plus, .minus {
    position: relative;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 10px;
    left: -11px;
}

.plus {
    background-image: url("../../../assests/images/plus.svg");
}

.minus {
    background-image: url("../../../assests/images/minus.svg");
}

.extra-generic-container-plus {
   display: none;
}

.radio-generic-container-extra:last-child .extra-generic-container-plus{ 
    display: block;
}

.radio-generic-container-extra:last-child .extra-generic-container-minus{ 
    display: none;
}
.radio-generic-help-group {
    display: flex;
}
.radio-generic-help {
    width: 35px;
    height: 35px;
    background-image: url("../../../assests/images/alert-circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    cursor: pointer;
}

.aditional-details {
    width: 99%;
    height: 148px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
    background: #EDEDED;
    margin-bottom: 15px;
}

.aditional-text {
    margin: 15px auto;
    font-family: 'Mulish';
    font-style: normal;
    font-size: 22px;
    margin-top: 2rem;
    color: var(--purple);
}

@media (max-width: 1440px) {
    .radio-container {
        width: 97%;
    }
    .radio-generic-container {
        width: 95%;
    }
}

@media (max-width: 850px) {
    .radio-generic-container {
        grid-template-columns: repeat(2, 1fr);
    }
    .radio-generic-container span {
        display: none;
    }
}
@media (max-width: 600px) {
    .radio-generic-container {
        display: flex;
        flex-direction: column;
    }
    .radio-generic-container label {
        white-space: normal;
    }
    .radio-generic-container {
        padding: 0 5px;
    }
}

@media (max-width: 550px) {
    .radio-generic-container {
        font-size: 16px;
    }
    .radio-generic-help-group{
        justify-content: center;
    }
}