.input-radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25px;
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}


.input-radio-container input[type='radio'] {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.input-radio-container input[type='radio'] {
  appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none; 
}

.input-radio-container input[type='radio']:before {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: white;
  content: '';
  display: flex;
  visibility: visible;
  border: 1px solid black;
}

.input-radio-container input[type='radio']:checked:after {
  width: 12px;
  height: 12px;
  border: 1px solid black;
  border-radius: 12px;    
  background-color: #ffa500;
  content: '';
  display: flex;
  position: relative;
  top: -18px;
  left: 4px;
}

.input-radio-container input[type=checkbox] {
    width: 24px;
    height: 24px;
    color: var(--orange);
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    background-color: #FFFFFF;
    cursor: pointer;
  }
  
  
  /* Pseudo element for check styling */
  
  .input-radio-container input[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
  }
  
  .input-radio-container input[type=checkbox]:checked {
    background-color: currentcolor;
  }
  
  .input-radio-container input[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }