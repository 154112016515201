.services-description {
    width: 100%;
    margin-bottom: 20px;
}

.services-description h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    color: #3B3663;
}

#description {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 30px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: #696687;
    margin: 0;
}

@media (max-width: 550px) {
    .services-description h2 {
        font-size: 20px !important;
    }
    #description {
        font-size: 16px;
    }
}


