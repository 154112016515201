.informative-web-container {
    /* width: fit-content; */
    margin: 0 auto;
    margin-top: 50px;
}

@media (max-width: 1440px) {
    .informative-web-container{
        width: 90%;
    }
}   